'use strict';

/**
 * import css/sass
 */

import css from '../css/reset.css';
import scss from '../css/main.scss';

import {tns} from 'tiny-slider/src/tiny-slider';

// menu
if (window.innerWidth < 767) {
	const menuBtn = document.getElementById("mobile-menu");
	const menuMob = document.querySelector(".header");
	menuBtn.addEventListener('click', e => {
		menuBtn.classList.toggle("open");
		menuMob.classList.toggle("open-mob");
	});

	const menuLinks = document.querySelectorAll('.menu-item > a')
	menuLinks.forEach(menuLink => menuLink.addEventListener('click', e => {
		menuMob.classList.toggle("open-mob");
	}));

	
	var slider = tns({
		container: '#instagram__carousel',
		items: 1,
		center: true,
		loop: true,
		nav: true,
		navPosition: 'bottom',
		controls: true,
		autoplay: true,
		speed: 600,
		autoplayTimeout: 4000,
		autoplayButtonOutput: false,
		controlsText: ['<', '>']
	});

}

// const menuLinks = document.querySelectorAll('.menu-item > a')
// menuLinks.forEach(menuLink => menuLink.addEventListener('click', e => {
//   let id = menuLink.getAttribute("href");
// 	let item = document.querySelector(id);
// 	item.scrollIntoView({behavior: "smooth"});
// }));

